#map {
	margin-top: 50px;
	height: 500px;
	width: 100%;
	display: block;
	position: relative; }
.contact-part1 {
	margin-top: 75px;
	margin-bottom: 25px;
	a {
		color: $light-gold;
		&:hover {
			color: $light-gold; } } }
.contact-part2 {
	border: 1px solid transparent;
	background-color: #fbfbfb;
	margin-top: 40px;
	padding: 30px;
	h2 {
		margin-top: 0px; } }
textarea {
	height: 200px; }
.contact_form {
	.button {
		background-color: #f8b134;
		margin: 19px auto;
		display: block; }
	.button:hover {
		background-color: #565655; }
	.text-right {
		width: 100%;
		display: block; } }
