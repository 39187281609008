/* FONT IMPORT */

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=latin-ext');

/* BASE STYLE SETTINGS */

html, body, h1, h2, h3, h4, h5, h6, a, ul, li, button, p, span, svg, text, rect, path, input, textarea, select, option {
  font-family: $font1; }

body {
  font-size: 18px;
  font-weight: 300;
  line-height: 30px; }
p {
  margin: 0 0 25px;
  strong {
    font-weight: 500; } }
ul {
  list-style: none;
  padding-left: 25px; }
li {
  padding-bottom: 15px;
  text-indent: -23px;
  line-height: 25px;
  strong {
    font-weight: 500; } }

li:before {
  content: "\f0c8";
  font-family: FontAwesome;
  color: $light-gold;
  margin-right: 15px;
  font-size: 9px;
  top: -2px;
  position: relative; }

.main-heading {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  color: $light-gold;
  padding-top: 40px;
  padding-bottom: 20px; }
.main-content {
  padding-bottom: 50px;
  padding-top: 60px;
  h2 {
    color: $light-gold;
    padding-top: 10px;
    padding-bottom: 20px; } }
.header-riccardoheading {
  color: $white;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  margin-top: 35px; }
.btn-main {
  background-color: $white;
  color: $light-gold;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding-top: 10px;
  transition: $transition;
  &:hover {
    background-color: $light-gold;
    color: $white;
    text-decoration: none;
    transition: $transition; } }

.btn-gold {
  display: inline-block;
  padding: 22px 45px;
  margin: 10px auto;
  background-color: $light-gold;
  color: $white;
  border: 1px solid $light-gold;
  text-transform: uppercase;
  &:hover {
    background-color: $white;
    color: $light-gold;
    border: 1px solid $light-gold; } }

.btn-podorys {
  float: right;
  display: block;
  position: relative;
  padding: 4px 20px;
  border: 1px solid $light-gold;
  margin: 20px 0px 20px 0px;
  color: $light-gold;
  transition: $transition;
  font-weight: 400;
  font-size: 16px;
  &:hover, &:focus {
    background-color: $light-gold;
    color: $white;
    text-decoration: none; } }
.btn-priestory {
  transition: $transition;
  border: 2px solid $white;
  padding: 25px 25px;
  border-radius: 0px;
  margin: 0 auto;
  display: block;
  font-weight: 700;
  text-transform: uppercase;
  &:hover, &:focus {
    text-decoration: none; } }

.alebo {
  font-style: italic; }
.nopad {
  padding-left: 0px;
  padding-right: 0px; }
.another {
  color: $white;
  text-align: center;
  font-size: 16px;
  text-decoration: underline;
  font-weight: 400;
  top: -15px;
  position: relative;
  &:hover {
    color: white;
    text-decoration: none; } }
.display-flex {
  display: flex;
  flex-wrap: wrap; }
.display-flex-mid {
  align-self: center; }

main {
  background-color: white; }
body {
  background-color: $dark-blue; }

/* MOBILE STUFF */
.mobile-center {
  text-align: inherit; }

.mobile-show {
  display: none; }

.mobile-hide {
  display: block; }

@media (max-width: 991px) {
  .nopad-mobile {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .mobile-center {
    text-align: center !important; }
  .mobile-show {
    display: block !important; }
  .mobile-hide {
    display: none !important; } }

/* END */
.translate-button {
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color .25s ease-out,color .25s ease-out;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: .85em 1em;
  margin: 1rem 0 1rem;
  font-size: .9rem;
  background-color: #ed6c08;
  color: #fefefe;
  text-transform: uppercase; }

.translate-button:hover {
  background-color: #c95c07;
  color: #fefefe; }
