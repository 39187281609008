@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

.navbar-default {
	box-shadow: none;
	background-image: none;
	background-color: $light-gold;
	height: 70px;
	border: 1px solid transparent;
	border-color: transparent;
	border-radius: 0px; }
.navbar-nav {
	float: none;
	text-align: center;
	margin-top: 8px; }
.navbar-nav > li {
	float: none;
	display: inline-block;
	text-indent: 0;
	&:before {
		content: "";
		display: none; } }
.navbar-default .navbar-nav > li > a {
	color: white;
	text-transform: uppercase;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: 10px;
	margin-right: 10px;
	font-size: 13px;
	&:hover {
		color: $white; } }
.navbar-default .navbar-nav {
	> li {
		> a {
			&:hover {
				background-color: $dark-gold; } } }
	> .active {
		> a {
			background-image: none;
			background-color: $dark-gold;
			box-shadow: none;
			color: $white;
			&:hover {
				background-color: $light-gold;
				color: $white; } } } }

.navbar-nav {
	li {
		a {
			transition: $transition;
			&:hover {
				transition: $transition; } } } }

@media (max-width: 991px) {
	.navbar-collapse.collapse {
		display: none !important; }
	.navbar-collapse.collapse.in {
		display: block !important; }
	.navbar-header .collapse, .navbar-toggle {
		display: block !important; }
	.navbar-header {
		float: none;
		width: 100%;
		margin-left: 0 !important;
		margin-right: 0 !important; }
	.navbar-nav > li {
		display: block; }
	.navbar-default {
		background-color: transparent;
		position: absolute;
		top: 35px;
		width: 100%; }
	.navbar-default .navbar-collapse, .navbar-default .navbar-form {
		background-color: transparent;
		border-color: transparent; }
	.navbar-collapse.collapsing, .navbar-collapse.collapse.in {
		background-color: $dark-gold;
		z-index: 999 !important;
		position: absolute;
		width: 100%;
		margin-left: 0 !important;
		margin-top: 10px; }
	.navbar-toggle {
		//background-color: $light-gold
		border-radius: 0px;
		//border: 1px solid $light-gold
		//border-color: $light-gold !important
		border-color: transparent !important;
		padding: 14px 10px; }
	.navbar-default .navbar-toggle .icon-bar {
		background-color: $white; }
	.navbar-default .navbar-toggle,
	.navbar-default .navbar-toggle {
		&:focus, &:hover {
			background-color: transparent;
 } }			//border-color: $dark-gold !important
	.navbar-default .navbar-nav {
		> li {
			> a {
				&:hover {
					background-color: $light-gold; } } } }
	.navbar-default .navbar-nav {
		> .active {
			> a {
				background-color: $light-gold; } } }
	.navbar-toggle .icon-bar {
		width: 35px;
		margin-bottom: 10px; } }
