/* PROJECT VARIABLES */

/* FOTNS */

$font1: 'Roboto', sans-serif;


/* COLORS */

$light-gold: #d3b574;
$dark-gold: #c1a15d;
$white: #ffffff;
$black: #393939;
$dark-blue: #1d303e;

$dark-gray: #3D3D3D;

$text-color: #1e313e;

$silver-border: #ebebeb;

$transition: 0.3s all;




/* whatever variables */
$primaryColor: #005eb2;		//tmavo modra
$secondaryColor: #05ca00;	//zelena pre hvoer
$tertiaryColor: #ffffff;		//biela
$quaternaryColor: red;	//
$pentiaryColor: green;		//

$gray: #c0c0c0;

$errorColor: #cc0000;
$warningColor: #ffcc00;
$successColor: #33cc00;

$body-font-family: 'Teko', sans-serif;
$base-font-size: 1rem;
$base-line-height: 1.5rem;

$gutter: 15px;


