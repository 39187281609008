@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

/* HOMEPAGE */

/* MAIN PART */
.homepage-header, .subpage-header {
	border-top: 5px solid $light-gold; }
.header-fast-contact {
	margin: 60px auto 10px auto;
	color: white;
	font-size: 13px;
	text-align: right;
	a {
		color: $light-gold;
		&:hover {
			color: $light-gold;
			text-decoration: underline; } } }
.header-left-fix {
	text-align: left !important;
	color: rgba(255,255,255,0.5);
	line-height: 20px;
	margin-top: 80px; }
.header-fb {
	&:after {
		content: "";
		display: block;
		height: 30px;
		width: 30px;
		background-image: url(../images/svg/facebook_icon.svg);
		float: right; } }
.homepage-header {
	min-height: 100vh;
	height: auto;
	display: block;
	background-image: url(../images/img/header.jpg);
	background-size: cover;
	background-position: center; }
.subpage-header {
	min-height: 450px;
	height: auto;
	display: block;
	background-image: url(../images/img/header.jpg);
	background-size: cover;
	background-position: bottom center; }

.header-logo {
	height: 125px;
	width: auto;
	display: block;
	margin: 30px auto 50px auto; }

.header-heading {
	color: $white;
	text-align: center;
	font-weight: 700;
	font-size: 54px;
	margin-top: 50px;
	margin-bottom: 0px;
	padding-top: 0px; }

.header-subheading {
	color: $white;
	text-align: center;
	font-weight: 300;
	font-size: 30px;
	line-height: 44px;
	margin-top: 0px; }

.btn-homepage {
	width: 256px;
	height: 70px;
	display: block;
	margin-top: 50px;
	margin-left: auto;
	margin-right: auto;
	text-transform: uppercase;
	padding-top: 22px;
	&:hover .podorysy {
		&:before {
			background-image: url(../images/svg/podorys_icon_white.svg); } }
	&:focus, &:active {
		color: white;
		text-decoration: none; } }
.podorysy {
	&:before {
		content: "";
		display: inline-block;
		width: 32px;
		height: 32px;
		background-image: url(../images/svg/podorys_icon.svg);
		background-size: cover;
		background-position: center;
		float: left;
		margin-left: 20px;
		margin-top: -5px; } }

@media (max-width: 991px) {
	.podorysy {
		&:before {
			background-image: url(../images/svg/podorys_icon_white.svg); } }
	.btn-homepage {
		&:hover .podorysy {
			&:before {
				background-image: url(../images/svg/podorys_icon.svg); } } } }


@media (max-width: 991px) {
	.subpage-header {
		min-height: 180px; }
	.header-fast-contact {
		padding-bottom: 35px;
		margin-top: 20px; }
	.header-fb {
		&:after {
			float: none;
			margin: 0 auto; } }
	.homepage-header {
		height: auto;
		min-height: auto;
		padding-bottom: 0px; }
	.header-heading {
		margin-top: 20px; }
	.header-logo {
		margin: 20px auto 10px auto;
		height: 80px;
		z-index: 9999;
		position: relative; }
	.header-heading {
		font-size: 35px; }
	.header-subheading {
		font-size: 20px;
		line-height: 34px; }
	.btn-homepage {
		margin-bottom: 10px;
		margin-top: 0px;
		background-color: $light-gold;
		color: white;
		margin-bottom: 40px;
		margin-top: 30px;
		&:hover {
			background-color: $white;
			color: $light-gold; } } }

@media (max-width: 500px) {
	.header-heading {
		font-size: 30px; }
	.btn-homepage {
		height: 50px;
		padding-top: 11px;
		width: 216px; }
	.podorysy {
		&:before {
			height: 22px;
			width: 22px;
			margin-top: 2px; } } }


/* VYHODY */

.home-vyhody {
	height: 200px;
	width: 100%;
	display: block;
	bottom: 0;
	position: absolute; }
.vyhoda {
	height: 100%;
	background-size: cover;
	background-position: center; }
.vyhoda-row {
	height: 50%;
	width: 100%;
	display: block;
	h3 {
		color: $white;
		text-align: center;
		text-transform: uppercase;
		font-size: 18px;
		font-weight: 700;
		padding-top: 20px; }
	p {
		width: 80%;
		margin: 0 auto;
		color: $text-color;
		font-size: 14px;
		text-align: center;
		strong {
			font-weight: 700;
			color: $text-color;
			font-size: 18px; } } }
.vyhoda-icon {
	display: block;
	height: 110px;
	width: 140px;
	background-color: $white;
	padding: 10px;
	margin: -60px auto; }

@media (max-width: 991px) {
	.home-vyhody {
		position: relative; }

	.vyhoda {
		min-height: 115px;
		height: auto;
		border-top: 1px solid white; }
	.vyhoda-row {
		padding-bottom: 10px;
		h3 {
			padding-top: 0px; } } }

@media (max-width: 768px) {
	.vyhoda {
		&:first-child {
			border-top: 1px solid transparent; } } }

@media (max-width: 768px) {
	.vyhoda {
		min-height: 80px; } }

/* HOME PRIESTORY */
.priestory {
	padding-bottom: 110px; }
.border-silver {
	border: 1px solid $silver-border; }
.border-silver-left {
	border-left: 1px solid $silver-border; }
.priestory-wrapper {
	margin-top: 45px;
	padding-bottom: 110px; }
.priestor-inner {
	display: none;
	align-self: center;
	color: white;
	position: absolute;
	text-align: center;
	width: 95%; }
.priestor-bg {
	margin-top: 15px;
	display: block;
	height: 240px;
	width: 100%;
	background-size: cover;
	background-position: center;
	display: flex;
	flex-wrap: wrap;
	transition: $transition;
	&:before {
		content: "";
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0);
		transition: $transition; }
	&:hover {
		&:before {
			background-color: rgba(211,181,116,0.8);
			transition: $transition; }
		> .priestor-inner {
			display: block;
			transition: $transition; } } }
.bbgld {
	background-image: url(../images/svg/bbgld.png); }
.skld {
	background-image: url(../images/svg/skld.png); }
.priestor-txt {
	padding-top: 30px;
	font-size: 14px;
	font-weight: 300;
	text-align: center;
	line-height: 25px;
	height: 200px;
	overflow: hidden;
	strong {
		font-weight: 700; } }

.priestor-cislo {
	height: 70px;
	text-align: center;
	a {
		font-size: 20px;
		color: $black;
		padding-top: 16px;
		display: block;
		&:hover {
			text-decoration: none; } } }
.priestor-border-1 {
	border-left: 2px solid $light-gold;
	border-top: 2px solid $light-gold;
	border-bottom: 2px solid $light-gold; }
.priestor-border-2 {
	border: 2px solid $light-gold; }
.priestor-nazov {
	//height: 70px
	background-color: $light-gold;
	color: $white;
	font-size: 28px;
	font-weight: 700;
	text-align: center;
	padding-top: 15px;
	padding-bottom: 15px;
	margin-bottom: 15px; }
.phone-icon {
	width: 65px;
	height: 65px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	top: 30px;
	position: relative;
	z-index: 5; }

@media (max-width: 991px) {
	.priestor-border-1 {
		border-bottom: 0px solid $light-gold;
		border-right: 2px solid $light-gold; }
	.phone-icon {
		top: 0%;
		padding-top: 10px;
		padding-bottom: 10px; } }

/* HOME - INFO */
.info-panel {
	background-image: url("../images/svg/prenajom_img_bg.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 475px;
	width: 100%;
	display: block; }
.info-heading-1 {
	display: block;
	padding-top: 80px;
	padding-bottom: 30px;
	text-align: center;
	font-size: 38px;
	color: $black;
	font-weight: 700;
	line-height: 45px;
	strong {
		font-weight: 700;
		color: $light-gold; } }
.info-heading-2 {
	text-align: center;
	height: 110px;
	display: flex;
	flex-wrap: wrap;
	background-color: $light-gold;
	font-size: 48px;
	color: $black;
	font-weight: 700;
	margin-bottom: 25px;
	p {
		text-align: center;
		align-self: center;
		margin: 0 auto; }
	strong {
		font-weight: 700;
		color: $white; } }
.btn-info-panel {
	display: block;
	width: 100%;
	height: 85px;
	padding-top: 30px;
	text-transform: uppercase;
	font-weight: 700; }

@media (max-width: 991px) {
	.info-panel {
		height: auto;
		padding-bottom: 25px; }
	.info-heading-1 {
		font-size: 25px;
		padding-top: 20px;
		padding-bottom: 10px; }
	.info-heading-2 {
		font-size: 27px;
		height: 90px; } }

/* SOME TRASH */

.references {
	padding-top: 30px;
	background-color: $tertiaryColor;
	color: #000;
	.heading-contact-form {
		margin-top: 0;
		font-size: 32px;
		text-align: left;
		color: #005eb2; }
	h2 {
		color: #000;
		span {
			display: inline-block;
			&:before {
				content: '';
				display: block;
				width: 80%;
				height: 6px;
				margin: 0px auto;
				background-color: $primaryColor; } } } }

