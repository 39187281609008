/* FORMULAR - HOME A PODSTRANKA */

#formular {
	h2 {
		padding-top: 75px;
		padding-bottom: 30px;
		color: $light-gold;
		font-size: 32px;
		font-weight: 700;
		text-align: center; }
	.subheading {
		font-size: 17px;
		font-weight: 300;
		text-align: center;
		line-height: 20px; } }
.form-part1 {
	background-color: $light-gold;
	border: 1px solid $dark-gold;
	//height: 110px
	height: auto; }
.form-select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-image: url(../images/svg/arrow_select_icon.svg);
	background-position: right 30px center;
	background-repeat: no-repeat;
	background-size: 14px 14px;
	margin-top: 23px;
	margin-bottom: 23px;
	border: 1px solid transparent;
	border-radius: 0px; }
.form-select, .form-input {
	height: 65px;
	padding-left: 30px; }
.form-input {
	margin-top: 20px;
	width: 100%;
	border: 1px solid $silver-border; }
.form-textarea {
	width: 100%;
	height: 185px;
	padding-top: 25px;
	padding-left: 30px;
	margin-top: 20px;
	resize: none;
	border: 1px solid $silver-border; }
.form-select, .form-input, .form-textarea, .form-send {
	font-size: 16px; }
.form-send {
	text-transform: uppercase;
	border: 1px solid transparent;
	border-radius: 0px;
	background-color: $light-gold;
	height: 65px;
	width: 100%;
	color: $white;
	font-weight: 700;
	margin-top: 25px;
	transition: $transition;
	&:hover {
		background-color: $white;
		color: $light-gold;
		transition: $transition;
		border: 1px solid $light-gold; } }
.form-margin {
	margin-top: 20px; }
.form-info {
	font-size: 13px;
	font-weight: 400 !important;
	color: #898989;
	text-align: justify;
	line-height: 20px;
	margin-bottom: 50px;
	strong {
		font-size: 15px;
		font-weight: 400 !important;
		color: $black; } }

@media (max-width: 991px) {
	#formular {
		h2 {
			padding-top: 30px; } }
	.form-input, .form-select, .form-send, .form-textarea {
		font-size: 14px; }
	.form-input, .form-select {
		height: 45px; }
	.form-input, .form-select, .form-textarea {
		padding-left: 15px; }
	.form-input, .form-textarea {
		margin-top: 10px; }
	.form-select {
		margin-top: 13px;
		margin-bottom: 13px; }
	.form-textarea {
		height: 100px; }
	.form-send {
		margin-top: 20px; }
	.form-info {
		margin-bottom: -20px;
		text-align: inherit; }
	.form-send {
		margin-bottom: 40px; } }


