/* FOOTER */

.footer {
	height: auto;
	min-height: 550px;
	width: 100%;
	background-color: $dark-blue;
	display: inline-block;
	position: relative;
	text-align: center; }
.footer-text {
	text-align: center;
	color: $white;
	font-weight: 300;
	margin-top: 30px;
	font-size: 14px;
	a {
		color: $light-gold;
		font-weight: 300 !important; } }
.footer-text p {
	display: inline-block;
	padding-right: 5px; }
.sixnet {
	height: 28px;
	width: auto;
	display: inline-block; }

.footer-heading {
	font-size: 17px;
	font-weight: 700;
	color: $white;
	padding-bottom: 0px; }
.footer-nav {
	list-style: none;
	padding: 0;
	color: $white;
	font-size: 16px;
	li {
		padding: 0;
		margin: 0;
		padding-bottom: 15px;
		text-indent: 0;
		&:before {
			content: "";
			display: none; }
		a {
			color: $white;
			&:hover {
				text-decoration: none;
				color: $light-gold; } } }
	.active {
		a {
			color: $light-gold;
			&:hover {
				text-decoration: none; } } } }
.footer-part2 {
	font-size: 16px;
	span {
		color: white;
		a {
			color: $light-gold;
			&:hover {
				color: $dark-gold;
				text-decoration: none; } } } }
.footer-part3 {
	font-size: 16px;
	a {
		color: $light-gold; }
	span {
		color: white;
		strong {
			color: $light-gold; } } }
.border-gold {
	border: 1px solid $light-gold; }
.fix-width {
	width: 70%;
	display: block;
	margin: 0 auto;
	padding-top: 10px; }

.footer-logo {
	height: 125px;
	width: auto;
	display: block;
	margin: 30px auto 30px auto; }

@media (max-width: 991px) {
	.footer {
		height: auto; }
	.footer-heading {
		padding-bottom: 0px; }
	.footer-logo {
		height: 80px; } }
