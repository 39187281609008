.priestory {
	h1 {
		padding-top: 75px;
		padding-bottom: 30px;
		color: $light-gold;
		font-size: 32px;
		font-weight: 700;
		text-align: center; }
	.podorys-content, .podorys-content p {
		font-size: 17px;
		font-weight: 300;
		text-align: center;
		line-height: 20px; } }

.tab-content {
	margin-top: 50px; }

.priestory-wrapper {
	> div {
		> div {
			margin-bottom: 25px; } } }
.stavy {
	margin-top: 30px;
	.full {
		color: $dark-gray; }
	.free {
		color: $light-gold; }
	span {
		font-size: 25px;
		display: block; } }

.pdf-margin {
	margin-right: 7px; }

@media (max-width: 991px) {
	.priestory {
		padding-bottom: 30px;
		h1 {
			padding-top: 30px; } } }
