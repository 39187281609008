/* FOTOGALERIA */
.fotogaleria-heading {
	font-size: 30px;
	font-weight: 700;
	text-align: center;
	color: $light-gold;
	padding-top: 40px;
	padding-bottom: 40px; }

.foto-item {
	background-size: cover;
	background-position: center;
	display: inline-block;
	width: 20%;
	height: 200px;
	float: left;
	transition: $transition;
	background-size: 100% 100%;
	&:hover {
		background-size: 110% 110%;
		transition: $transition; } }
.foto-home-more {
	display: none; }

@media (max-width: 991px) {
	.foto-item {
		width: 25%; }
	.foto-mobile-homepage {
		width: 100% !important; }
	.foto-mobile-hide {
		display: none; }
	.foto-home-more {
		display: block;
		text-align: center; } }
@media (max-width: 768px) {
	.foto-item {
		width: 50%; }
	.foto-mobile-homepage {
		width: 100% !important; } }
